import React, { useState, useEffect } from "react";
import { View, Text, Button, StyleSheet } from "react-native";
import { getFirestore, collection, getDocs, updateDoc, query, where, setDoc, addDoc, doc, deleteDoc, onSnapshot, getDoc } from "firebase/firestore";
import AWS from "aws-sdk";
import { useUser } from "../Widgets/UserContext";
import Popup from "../Widgets/Popup";

const isDevelopment = process.env.NODE_ENV === "development";

const s3 = new AWS.S3();
const db = getFirestore();

AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});

const Admin = () => {
	const [organizations, setOrganizations] = useState([]);
	const [approvedOrgs, setApprovedOrgs] = useState([]);
	const [deniedOrgs, setDeniedOrgs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [catalogueItems, setCatalogueItems] = useState([]); // New state for catalogue items

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 25;
	const [searchQuery, setSearchQuery] = useState("");

	const [affiliateUrl, setAffiliateUrl] = useState("");
	const [itemPreview, setItemPreview] = useState(null);

	const [loadingStep, setLoadingStep] = useState("");
	const [isFetching, setIsFetching] = useState(false);
	const [showStillWorking, setShowStillWorking] = useState(false);

	const [category, setCategory] = useState(""); // For selecting a category
	const categories = ["Bedroom", "Bathroom", "Decor", "Health & Personal Care", "Kitchen", "Laundry & Cleaning", "Electronics & Office Supplies", "Office Supplies", "Storage & Organization"];

	const [categoryError, setCategoryError] = useState(false);

	const [filter, setFilter] = useState("all"); // 'all', or any of the categories
	const [sort, setSort] = useState("none"); // 'none', 'priceLowHigh', 'priceHighLow'

	const [itemName, setItemName] = useState("");
	const [itemDescription, setItemDescription] = useState("");
	const [itemPrice, setItemPrice] = useState("");
	const [itemImage, setItemImage] = useState("");

	const [selectedPrice, setSelectedPrice] = useState('');
	const [priceUpdates, setPriceUpdates] = useState({}); // For individual edits
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [bulkPrice, setBulkPrice] = useState('');
	const [bulkDescription, setBulkDescription] = useState('');

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = "https://api.mytassel.com";

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const { currentUserData } = useUser();
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showAdmin, setShowAdmin] = useState(false); // New state for showing admin content

	const [testEmail, setTestEmail] = useState("");
	const [emailType, setEmailType] = useState("genericWelcome");
	const [emailParams, setEmailParams] = useState({});
	const [emailTestResult, setEmailTestResult] = useState("");
	const [recipientEmail, setRecipientEmail] = useState("");

	const [isMaintenance, setIsMaintenance] = useState(false);
	const [mismatchedUsers, setMismatchedUsers] = useState([]);

	// Add state for subcategories
	const [subCategories, setSubCategories] = useState({
		"Bedroom": ["Sheets", "Comforter", "Bed In a Bag", "Pillow", "Pillow Protector", "Body Pillow", "Mattress Topper", "Mattress Protector", "Throw Blanket", "Study Pillow", "Cosmetic Organizer", "Misc"],
		"Bathroom": ["Towel Set", "Bluetooth Speaker", "Shower Caddy", "Shower Shoes", "Bathrobe", "Shower Wrap", "Hair Wrap", "Bathroom Rug", "Shower Curtain", "Tension Rod", "Misc"],
		"Decor": ["Throw Rug", "Throw Pillows", "Clip Lamps", "Desk Lamp", "Floor Lamp", "Lounge Seating", "Mirror", "Picture Frames", "String Lights", "Tension Rod", "Vanity Mirror", "Window Curtains", "Wall Safe Adhesive", "Dry Erase/Bulletin Board", "Standing Fan", "Bed Fan/Clip Fan", "Doorstopper", "Lap Desk", "Room Decor", "Faux Plants", "Floor Pillows", "Misc"],
		"Health & Personal Care": ["Hair Dryer", "Styling Tools", "Hair Removal", "First Aid Kit", "Pain Reliever", "Sewing Kit", "Exercise Equipment", "Humidifier", "Diffuser", "Diffuser Oils", "Air Purifier", "Noise Machine", "Tool Set", "Misc"],
		"Kitchen": ["Microwave", "Refrigerator", "Personal Refrigerator", "Coffee Maker", "Rice Cooker", "Cooking Tools", "Smoothie Maker", "Air Fryer", "Dishes", "Silverware", "Water Bottles", "Travel Mug", "Can Opener", "Water Filtering", "Mugs", "Snack Bundles", "Food Storage", "Pots/Pans", "Cooking Utensils", "Misc"],
		"Laundry & Cleaning": ["Cleaning Bundle", "Laundry Basket", "Hamper", "Laundry Detergent", "Odor Eliminator", "Steamer", "Iron", "Ironing Board", "Vacuums", "Laundry Bag", "Broom & Dust Pan", "Garbage Can", "Misc"],
		"Electronics & Office Supplies": ["Television", "Laptop", "Chargers", "Flash Drive", "Hard Drive", "Extension Cords", "Headphones", "Printer", "Alarm Clock", "Bluetooth Speaker", "Personal Alarm", "Room Alarm", "Firestick", "Amazon Speakers", "Day Planner", "Backpack", "Pens", "Pencils", "Highlighters", "Notebooks", "Stapler", "Scissors", "Printer paper", "Sticky Notes", "Dry Erase Markers", "Desk Set", "Misc"],
		"Storage & Organization": ["Bedside Shelf/Caddy", "Closet Organization", "Shoe Storage/Racks", "Hangers", "Trunk/Storage", "Underbed Storage", "Drawers", "Over Door Organization", "Rolling Cart", "Desk Organizer", "Wall Key Organizer", "Moving Bags", "Luggage", "Misc"]
	});

	const [selectedSubCategory, setSelectedSubCategory] = useState("");

	// Function to handle subcategory change
	const handleSubCategoryChange = async (itemId, newSubCategory) => {
		try {
			const itemRef = doc(db, "catalogueItems", itemId);
			await updateDoc(itemRef, { subCategory: newSubCategory });

			setCatalogueItems(
				catalogueItems.map((item) => {
					if (item.id === itemId) {
						return { ...item, subCategory: newSubCategory };
					}
					return item;
				})
			);

			// Automatically select the product
			setSelectedProducts((prevSelectedProducts) => {
				if (!prevSelectedProducts.includes(itemId)) {
					return [...prevSelectedProducts, itemId];
				}
				return prevSelectedProducts;
			});
		} catch (error) {
			console.error("Error updating subcategory:", error);
		}
	};

	useEffect(() => {
		if (isDevelopment) {
			setShowAdmin(true); // Bypass login and authorization checks in development
		} else {
			if (!currentUserData || currentUserData?.accountType !== "admin") {
				setShowLoginPopup(true);
				setShowAdmin(false);
				setTestEmail(currentUserData?.email);
			} else {
				setShowAdmin(true);
			}
		}
	}, [currentUserData]);

	useEffect(() => {
		fetchOrganizations();
		fetchCatalogueItems();
		console.warn(SERVER_URL);
	}, []);

	useEffect(() => {
		fetchCatalogueItems();
	}, [filter, sort]);

	useEffect(() => {
		const statusDocRef = doc(db, "maintenance", "status"); // Ensure this path is correct
		const unsubscribe = onSnapshot(statusDocRef, (doc) => {
			if (doc.exists()) {
				setIsMaintenance(doc.data().isMaintenanceActive); // Corrected field name
				console.log("Maintenance status set!");
			} else {
				console.log("No such document!");
			}
		});
		return () => unsubscribe(); // Cleanup subscription on unmount
	}, []); // No need to add db as a dependency since it's imported

	useEffect(() => {
		scanForUidMismatches();
	}, []);

	const toggleMaintenance = async () => {
		console.log("Toggle Maintenance button pressed"); // Debugging line
		const password = prompt("Please retype the site password to confirm:");
		if (password === "Newtjhawk27") { // Check if the password matches
			const newMaintenanceStatus = !isMaintenance; // Toggle the maintenance status
			await updateDoc(doc(db, "maintenance", "status"), {
				isMaintenanceActive: newMaintenanceStatus,
				startTime: newMaintenanceStatus ? new Date().toLocaleString() : null, // Changed to standard language format
			});
		} else {
			alert("Incorrect password. Maintenance status not changed.");
		}
	};

	const fetchOrganizations = async () => {
		const querySnapshot = await getDocs(collection(db, "new organizations"));
		const orgs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		setOrganizations(orgs);
		setLoading(false);
	};

	// Adjusted fetchCatalogueItems to optionally filter and sort items
	const fetchCatalogueItems = async () => {
		let querySnapshot = await getDocs(collection(db, "catalogueItems"));
		let items = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				...data,
				subCategory: data.subCategory || "Default Subcategory" // Use default or existing subcategory
			};
		});

		// Filter items if a category is selected
		if (filter !== "all") {
			items = items.filter((item) => item.category === filter);
		}

		// Sort items based on the selected sorting method
		if (sort === "priceLowHigh") {
			items.sort((a, b) => parseFloat(a.price.replace("$", "")) - parseFloat(b.price.replace("$", "")));
		} else if (sort === "priceHighLow") {
			items.sort((a, b) => parseFloat(b.price.replace("$", "")) - parseFloat(a.price.replace("$", "")));
		}

		setCatalogueItems(items);
	};

	// Function to handle search input change
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(1); // Reset to first page to start filtering from the beginning
	};

	// Filter and paginate items
	const filteredItems = catalogueItems.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	// Calculate total pages
	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

	// Function to change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// Calculate the items to display
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const handleApprove = (org) => {
		const updatedOrg = { ...org, approved: true, code: generateInstitutionCode(org.organizationType) };
		setApprovedOrgs([...approvedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const handleDeny = (org) => {
		const updatedOrg = { ...org, approved: false };
		setDeniedOrgs([...deniedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const uploadApproved = async () => {
		if (window.confirm("Are you sure you want to upload these entries?")) {
			try {
				// Upload each approved organization to Firestore
				for (const org of approvedOrgs) {
					await addDoc(collection(db, "approvedOrganizations"), formatOrgForUpload(org));
				}

				// Clear approved organizations
				setApprovedOrgs([]);

				// Update Firestore for each organization
				approvedOrgs.forEach((org) => {
					deleteDoc(doc(db, "new organizations", org.id));
				});
			} catch (error) {
				console.error("Error during upload", error);
			}
		}
	};

	const formatOrgForUpload = (org) => {
		return {
			name: org.name,
			domains: [org.website],
			web_pages: [org.website],
			country: org.location || "",
			alpha_two_code: org.location || "",
			"state-province": org.location || null,
			organizationCode: org.code,
			members: {},
		};
	};

	const purgeDenied = async () => {
		if (window.confirm("Are you sure you want to permanently delete these entries?")) {
			for (const org of deniedOrgs) {
				await deleteDoc(doc(db, "new organizations", org.name));
			}
			setDeniedOrgs([]);
		}
	};

	const handleEdit = (id, field, value) => {
		setOrganizations(
			organizations.map((org) => {
				if (org.id === id) {
					return { ...org, [field]: value };
				}
				return org;
			})
		);
	};

	const handleReevaluate = (org, listSetter, fromList) => {
		listSetter(fromList.filter((o) => o.id !== org.id));
		setOrganizations([...organizations, org]);
	};

	const fetchItemPreview = async () => {
		const cleanedAffiliateUrl = sanitizeAffiliateUrl(affiliateUrl);
		setAffiliateUrl(cleanedAffiliateUrl); // Update the state with the sanitized URL

		setLoadingStep("Checking for duplicates...");
		const isDuplicate = await checkForDuplicateLink(cleanedAffiliateUrl);

		if (isDuplicate) {
			setLoadingStep("This affiliate link is already in the database.");
			// Optionally, reset affiliateUrl or handle as needed
			setAffiliateUrl("");
			return; // Stop execution to prevent adding a duplicate
		}

		setLoadingStep("Fetching preview, please wait...");
		setIsFetching(true);
		setShowStillWorking(false); // Reset the "still working" message state

		// Set a timeout to show the "still working" message after 7 seconds
		const stillWorkingTimeout = setTimeout(() => {
			setShowStillWorking(true);
		}, 7000); // 7000 milliseconds = 7 seconds

		try {
			const response = await fetch(`${SERVER_URL}/fetch-preview`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ url: affiliateUrl }),
			});
			clearTimeout(stillWorkingTimeout); // Clear the timeout upon fetch completion
			if (response.ok) {
				setLoadingStep("Processing data...");
				const previewData = await response.json();
				setItemPreview(previewData);
				setLoadingStep("Preview fetched successfully.");
			} else {
				console.error("Error:", response);
				throw new Error("Failed to fetch item preview");
			}
		} catch (error) {
			setLoadingStep(`Error: ${error.message}`);
			console.error("Failed to fetch item preview:", error);
		} finally {
			setIsFetching(false);
			setShowStillWorking(false); // Ensure "still working" message is hidden after fetch
		}
	};

	const checkForDuplicateLink = async (affiliateUrl) => {
		// Assuming you're using Firestore as indicated in previous snippets
		const querySnapshot = await getDocs(query(collection(db, "catalogueItems"), where("url", "==", affiliateUrl)));
		return !querySnapshot.empty; // Returns true if a duplicate exists, false otherwise
	};

	const sanitizeAffiliateUrl = (url) => {
		// Trim leading and trailing spaces
		let cleanedUrl = url.trim();

		// Further cleaning can go here, e.g., removing or replacing specific characters
		// For example, to remove all spaces (not just leading/trailing), you could use:
		// cleanedUrl = cleanedUrl.replace(/\s+/g, '');

		return cleanedUrl;
	};

	const submitItemToDatabase = async () => {
		if (!category) {
			alert("Please select a category.");
			setCategoryError(true);
			return;
		}

		if (!selectedSubCategory) {
			alert("Please select a subcategory.");
			setCategoryError(true);
			return;
		}

		// Determine if we're using fetched data or manual entry
		const itemData = manualEntry
			? {
				name: itemName,
				description: itemDescription,
				price: itemPrice,
				image: itemImage,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			}
			: {
				name: itemPreview.title,
				description: itemPreview.description,
				price: itemPreview.price,
				image: itemPreview.imageUrl,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			};

		try {
			await addDoc(collection(db, "catalogueItems"), itemData);

			setCatalogueItems([...catalogueItems, itemData]);
			setLoadingStep("Item added successfully to the catalogue.");

			// Reset the form fields
			setAffiliateUrl("");
			setItemPreview(null);
			setCategory("");
			setSelectedSubCategory("");
			setCategoryError(false);

			fetchCatalogueItems();
		} catch (error) {
			console.error("Error adding item to catalogue:", error);
			setLoadingStep("Failed to add item to catalogue.");
		}
	};

	const [manualEntry, setManualEntry] = useState(false); // New state to toggle manual entry

	// Toggle function
	const toggleManualEntry = () => {
		setManualEntry(!manualEntry);
		// Reset item preview and manual entry fields
		setItemPreview(null);
		setItemName("");
		setItemDescription("");
		setItemPrice("");
		setItemImage("");
	};

	// Function to delete an item
	const deleteItem = async (itemId) => {
		await deleteDoc(doc(db, "catalogueItems", itemId));
		fetchCatalogueItems(); // Refetch items after deletion
	};

	const handlePriceChange = (event) => {
		setSelectedPrice(event.target.value);
	};

	const handleIndividualPriceChange = (productId, newPrice) => {
		setPriceUpdates(prev => ({ ...prev, [productId]: newPrice }));
	};

	// Function to handle product selection
	const handleSelectProduct = (productId) => {
		setSelectedProducts(prevSelectedProducts =>
			prevSelectedProducts.includes(productId)
				? prevSelectedProducts.filter(id => id !== productId)
				: [...prevSelectedProducts, productId]
		);
	};

	const [showUpdatePopup, setShowUpdatePopup] = useState(false);
	const [estimatedTime, setEstimatedTime] = useState(0);

	const updateProducts = async () => {
		if (selectedProducts.length === 0) {
			alert('Please select at least one product.');
			return;
		}

		const updates = {};
		if (selectedCategory) updates.category = selectedCategory;
		if (bulkPrice) updates.price = bulkPrice;
		if (bulkDescription) updates.description = bulkDescription;

		// Estimate time based on the number of selected products
		const estimatedTimeInSeconds = selectedProducts.length * 2; // Example: 2 seconds per product
		setEstimatedTime(estimatedTimeInSeconds);
		setShowUpdatePopup(true);

		try {
			for (const productId of selectedProducts) {
				const productRef = doc(db, "catalogueItems", productId);
				await updateDoc(productRef, updates);
			}

			alert('Products updated successfully!');
			fetchCatalogueItems(); // Refetch products to get updated data
			setSelectedProducts([]); // Clear selections
			setSelectedCategory('');
			setBulkPrice('');
			setBulkDescription('');
		} catch (error) {
			console.error('Error updating products:', error);
			alert('Error updating products');
		} finally {
			setShowUpdatePopup(false);
		}
	};

	// Add this function inside your component
	const toggleSelectAllProducts = () => {
		if (selectedProducts.length === catalogueItems.filter(item => item.category === filter).length) {
			setSelectedProducts([]);
		} else {
			setSelectedProducts(catalogueItems.filter(item => item.category === filter).map(item => item.id));
		}
	};

	// To make category dropdown red if there's an error
	const categorySelectStyle = categoryError ? { ...styles.input, borderColor: "red" } : styles.input;
	//     https://amzn.to/3UCGBby

	const handleEmailParamChange = (param, value) => {
		setEmailParams((prevParams) => ({
			...prevParams,
			[param]: value,
		}));
	};

	const handleTestEmailSubmit = async (event) => {
		event.preventDefault();
		try {
			const response = await fetch(`${SERVER_URL}/test-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email: recipientEmail, emailType, ...emailParams }),
			});

			if (!response.ok) {
				throw new Error("Failed to send test email");
			}

			const result = await response.text();
			setEmailTestResult(result);
		} catch (error) {
			setEmailTestResult("Failed to send test email");
			alert("Failed to send test email! Please try again.");
		}
	};

	const scanForUidMismatches = async () => {
		const usersRef = collection(db, "users");
		const querySnapshot = await getDocs(usersRef);
		const mismatchedUsers = [];

		querySnapshot.forEach((doc) => {
			const userData = doc.data();
			const uid = userData.uid;
			const eventsUrl = userData.pages?.events;
			const registryUrl = userData.pages?.registry;

			// Only process UIDs that start with "G"
			if (uid.startsWith("G")) {
				if (
					(eventsUrl && !eventsUrl.includes(uid)) ||
					(registryUrl && !registryUrl.includes(uid))
				) {
					mismatchedUsers.push({
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
						uid: uid,
						eventsUrl: eventsUrl || "N/A",
						registryUrl: registryUrl || "N/A",
					});
				}
			}
		});

		if (mismatchedUsers.length > 0) {
			await alertMismatchedUsers(mismatchedUsers);
			setMismatchedUsers(mismatchedUsers); // Update state to display on the admin page
		}
	};

	const alertMismatchedUsers = async (mismatchedUsers) => {
		try {
			const response = await fetch(`${SERVER_URL}/alert-mismatched-users`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ mismatchedUsers }),
			});

			if (!response.ok) {
				throw new Error("Failed to send alert email");
			}
		} catch (error) {
			console.error("Error sending alert email:", error);
		}
	};

	const toggleCatalogueVisibility = async (field) => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const currentData = docSnap.data();
				const newValue = !currentData[field];
				await updateDoc(docRef, {
					[field]: newValue
				});
				setVisibilitySettings((prevSettings) => ({
					...prevSettings,
					[field]: newValue
				}));
			} else {
				// If the document doesn't exist, create it with default values
				await setDoc(docRef, {
					hidePrice: false,
					hideImages: false,
					hideAll: false
				});
				fetchVisibilitySettings(); // Fetch again to update state
			}
		} catch (error) {
			console.error("Error updating catalogue visibility:", error);
		}
	};

	const [visibilitySettings, setVisibilitySettings] = useState({
		hidePrice: false,
		hideImages: false,
		hideAll: false
	});

	const fetchVisibilitySettings = async () => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setVisibilitySettings(docSnap.data());
			}
		} catch (error) {
			console.error("Error fetching visibility settings:", error);
		}
	};

	useEffect(() => {
		fetchVisibilitySettings();
	}, []);

	// Button styles based on visibility settings
	const getButtonStyle = (field) => {
		return visibilitySettings[field] ? styles.buttonActive : styles.buttonInactive;
	};

	if (loading) {
		return <div style={styles.loading}>Loading...</div>;
	}

	return (
		<>

			{showLoginPopup && <View style={styles.popupContainer}> <Popup style={styles.popup} type="login" onClose={() => setShowLoginPopup(false)} isAdminPage={true} /> </View>}

			{showAdmin && (
				<div style={styles.container}>
					<h1 style={styles.header}>Admin Dashboard</h1>

					{/* Maintenance Section */}
					<div>
						<h2 style={styles.subHeader}>Maintenance Status</h2>
						{isMaintenance && (
							<div>
								<p>Maintenance Started At: {new Date().toLocaleString()}</p>
							</div>
						)}
						<button style={isMaintenance ? styles.buttonDeny : styles.buttonApprove} onClick={toggleMaintenance}>{isMaintenance ? "End Maintenance" : "Start Maintenance"}</button>
					</div>

					{/* Pending Organizations Section */}
					<h2 style={styles.subHeader}>Pending Organizations</h2>
					<table style={styles.table}>
						<thead style={styles.tableHeader}>
							<tr>
								<th>Name</th>
								<th>Address</th>
								<th>Website</th>
								<th>Type</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{organizations.map((org) => (
								<tr key={org.id} style={styles.tableRow}>
									<td>
										<input type="text" value={org.name} onChange={(e) => handleEdit(org.id, "name", e.target.value)} style={styles.input} />
									</td>
									<td>
										<input type="text" value={org.location} onChange={(e) => handleEdit(org.id, "address", e.target.value)} style={styles.input} />
									</td>
									<td>
										<input type="text" value={org.website} onChange={(e) => handleEdit(org.id, "website", e.target.value)} style={styles.input} />
									</td>
									<td>{org.organizationType}</td>
									<td>
										<button onClick={() => handleApprove(org)} style={styles.buttonApprove}>
											Approve
										</button>
										<button onClick={() => handleDeny(org)} style={styles.buttonDeny}>
											Deny
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{/* Approved Organizations Section */}
					<h2 style={styles.subHeader}>Approved Organizations</h2>
					<table style={styles.table}>
						<thead style={styles.tableHeader}>
							<tr>
								<th>Name</th>
								<th>Address</th>
								<th>Website</th>
								<th>Type</th>
								<th>Code</th>
								<th>Reevaluate</th>
							</tr>
						</thead>
						<tbody>
							{approvedOrgs.map((org) => (
								<tr key={org.id} style={styles.tableRow}>
									<td>{org.name}</td>
									<td>{org.location}</td>
									<td>{org.website}</td>
									<td>{org.organizationType}</td>
									<td>{org.code}</td>
									<td>
										<button onClick={() => handleReevaluate(org, setApprovedOrgs, approvedOrgs)} style={styles.buttonReevaluate}>
											Reevaluate
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button onClick={uploadApproved} style={styles.buttonApprove}>
						Upload Approved
					</button>

					{/* Denied Organizations Section */}
					<h2 style={styles.subHeader}>Denied Organizations</h2>
					<table style={styles.table}>
						<thead style={styles.tableHeader}>
							<tr>
								<th>Name</th>
								<th>Address</th>
								<th>Website</th>
								<th>Type</th>
								<th>Reevaluate</th>
							</tr>
						</thead>
						<tbody>
							{deniedOrgs.map((org) => (
								<tr key={org.id} style={styles.tableRow}>
									<td>{org.name}</td>
									<td>{org.location}</td>
									<td>{org.website}</td>
									<td>{org.organizationType}</td>
									<td>
										<button onClick={() => handleReevaluate(org, setDeniedOrgs, deniedOrgs)} style={styles.buttonReevaluate}>
											Reevaluate
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button onClick={purgeDenied} style={styles.buttonDeny}>
						Purge Denied
					</button>

					{/* Email Test Form */}
					<div id="email-test-form">
						<h3>Test Emails</h3>
						<form onSubmit={handleTestEmailSubmit}>
							<label htmlFor="recipientEmail">Recipient Email:</label>
							<input
								type="email"
								id="recipientEmail"
								value={recipientEmail}
								placeholder="Enter recipient's email address"
								onChange={(e) => setRecipientEmail(e.target.value)}
								required
								style={styles.input}
							/>

							<select
								id="emailType"
								value={emailType}
								onChange={(e) => setEmailType(e.target.value)}
								required
								style={styles.input}
							>
								<option value="genericWelcome">Generic Welcome Email</option>
								<option value="graduateWelcome">Graduate Welcome Email</option>
								<option value="contribution">Contribution Email</option>
								<option value="purchaseConfirmation">Purchase Confirmation Email</option>
								<option value="yearbookMessage">Yearbook Message Notification</option>
								<option value="accessRequest">Access Request Email</option>
								<option value="goalReached">Goal Reached Email</option>
								<option value="ecard">E-Card Email</option>
								<option value="invitation">Invitation Email</option>
								<option value="mismatchedUsersAlert">Mismatched Users Alert</option>
								{/* Add other email types here as needed */}
							</select>

							{/* Conditional input fields based on email type */}
							{emailType === "genericWelcome" && (
								<>
									<input
										type="text"
										placeholder="First Name"
										onChange={(e) => handleEmailParamChange("firstName", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "graduateWelcome" && (
								<>
									<input
										type="text"
										placeholder="First Name"
										onChange={(e) => handleEmailParamChange("firstName", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "contribution" && (
								<>
									<input
										type="text"
										placeholder="Contribution Amount"
										onChange={(e) => handleEmailParamChange("contributionAmount", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Contributor Name"
										onChange={(e) => handleEmailParamChange("contributorName", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "purchaseConfirmation" && (
								<>
									<input
										type="text"
										placeholder="Order ID"
										onChange={(e) => handleEmailParamChange("orderId", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Purchase Details"
										onChange={(e) => handleEmailParamChange("purchaseDetails", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "yearbookMessage" && (
								<>
									<input
										type="text"
										placeholder="Page Owner"
										onChange={(e) => handleEmailParamChange("pageOwner", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Message Content"
										onChange={(e) => handleEmailParamChange("messageContent", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "accessRequest" && (
								<>
									<input
										type="text"
										placeholder="Page Owner"
										onChange={(e) => handleEmailParamChange("pageOwner", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Requester"
										onChange={(e) => handleEmailParamChange("requester", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "goalReached" && (
								<>
									<input
										type="text"
										placeholder="Page Owner Name"
										onChange={(e) => handleEmailParamChange("pageOwnerName", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Goal Amount"
										onChange={(e) => handleEmailParamChange("goalAmount", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "ecard" && (
								<>
									<input
										type="text"
										placeholder="Sender Name"
										onChange={(e) => handleEmailParamChange("senderName", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Message"
										onChange={(e) => handleEmailParamChange("message", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "invitation" && (
								<>
									<input
										type="text"
										placeholder="Event Name"
										onChange={(e) => handleEmailParamChange("eventName", e.target.value)}
										style={styles.input}
									/>
									<input
										type="text"
										placeholder="Invitation Details"
										onChange={(e) => handleEmailParamChange("invitationDetails", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							{emailType === "mismatchedUsersAlert" && (
								<>
									<input
										type="text"
										placeholder="Mismatched Users List"
										onChange={(e) => handleEmailParamChange("mismatchedUsersList", e.target.value)}
										style={styles.input}
									/>
								</>
							)}

							<button type="submit" style={styles.buttonApprove}>Send Test Email</button>
						</form>
						{emailTestResult && <div id="emailTestResult">{emailTestResult}</div>}
					</div>

					{/* Display Mismatched Users */}
					<h2 style={styles.subHeader}>Mismatched Users</h2>
					<table style={styles.table}>
						<thead style={styles.tableHeader}>
							<tr>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Email</th>
								<th>UID</th>
								<th>Events URL</th>
								<th>Registry URL</th>
							</tr>
						</thead>
						<tbody>
							{mismatchedUsers.map((user) => (
								<tr key={user.uid} style={styles.tableRow}>
									<td>{user.firstName}</td>
									<td>{user.lastName}</td>
									<td>{user.email}</td>
									<td>{user.uid}</td>
									<td>{user.eventsUrl}</td>
									<td>{user.registryUrl}</td>
								</tr>
							))}
						</tbody>
					</table>

					{/* Add Affiliates Section */}
					<h2 style={styles.subHeader}>Affiliate Link Details</h2>
					<div>
						<h4>Toggle Catalogue Visibility</h4>
						<div style={styles.buttonContainer}>
							<button onClick={() => toggleCatalogueVisibility('hidePrice')} style={getButtonStyle('hidePrice')}>
								{visibilitySettings.hidePrice ? "Show Price" : "Hide Price"}
							</button>
							<button onClick={() => toggleCatalogueVisibility('hideImages')} style={getButtonStyle('hideImages')}>
								{visibilitySettings.hideImages ? "Show Images" : "Hide Images"}
							</button>
							<button onClick={() => toggleCatalogueVisibility('hideAll')} style={getButtonStyle('hideAll')}>
								{visibilitySettings.hideAll ? "Show All" : "Hide All"}
							</button>
						</div>
					</div>


					<div>
						<input type="text" placeholder="Affiliate URL" value={affiliateUrl} onChange={(e) => setAffiliateUrl(e.target.value)} style={styles.input} />
						<div>
							<select
								value={category}
								onChange={(e) => {
									setCategory(e.target.value);
									setSelectedSubCategory("");
								}}
								style={categorySelectStyle}
							>
								<option value="">Select a Category</option>
								{categories.map((cat) => (
									<option key={cat} value={cat}>
										{cat}
									</option>
								))}
							</select>

							<select
								value={selectedSubCategory}
								onChange={(e) => setSelectedSubCategory(e.target.value)}
								style={styles.input}
							>
								<option value="">Select a Subcategory</option>
								{subCategories[category]?.map((subCat) => (
									<option key={subCat} value={subCat}>
										{subCat}
									</option>
								))}
							</select>
						</div>

						{!manualEntry ? (
							<>
								<button onClick={fetchItemPreview} style={styles.buttonApprove}>
									Fetch Preview
								</button>
								<button onClick={toggleManualEntry} style={styles.buttonDeny}>
									Enter Details Manually
								</button>
							</>
						) : (
							<>
								<input type="text" placeholder="Item Name" value={itemName} onChange={(e) => setItemName(e.target.value)} style={styles.input} />
								<textarea placeholder="Item Description" value={itemDescription} onChange={(e) => setItemDescription(e.target.value)} style={styles.input} />
								<input type="text" placeholder="Item Price" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} style={styles.input} />
								<input type="text" placeholder="Item Image URL" value={itemImage} onChange={(e) => setItemImage(e.target.value)} style={styles.input} />
								<button onClick={toggleManualEntry} style={styles.buttonDeny}>
									Fetch Preview Instead
								</button>
								<button
									onClick={submitItemToDatabase}
									style={category && selectedSubCategory && itemName && itemDescription && itemPrice && itemImage ? styles.buttonApprove : styles.buttonDisabled}
									disabled={!category || !selectedSubCategory || !itemName || !itemDescription || !itemPrice || !itemImage}
								>
									Add to Catalogue
								</button>
							</>
						)}
						{!isFetching && itemPreview && (
							<button
								onClick={submitItemToDatabase}
								style={category && selectedSubCategory ? styles.buttonApprove : styles.buttonDisabled}
								disabled={!category || !selectedSubCategory}
							>
								Add to Catalogue
							</button>
						)}

						{loadingStep && <div style={{ padding: "10px", backgroundColor: "#f0f0f0", margin: "10px 0", borderRadius: "5px" }}>{loadingStep}</div>}
						{showStillWorking && <div style={{ padding: "10px", backgroundColor: "#f0f0f0", margin: "10px 0", borderRadius: "5px" }}>Still working...</div>}
					</div>

					{itemPreview && (
						<div>
							<h3>Item Preview:</h3>
							<img src={itemPreview.imageUrl} alt="Preview" style={{ width: "250px", height: "250px" }} />
							<p>Name: {itemPreview.title}</p>
							<p>Description: {itemPreview.description}</p>
							<p>Price: {itemPreview.price}</p>
							<button onClick={submitItemToDatabase} style={styles.buttonApprove}>
								Add to Catalogue
							</button>
						</div>
					)}

					{/* Catalogue Items Section with delete button */}
					<h2 style={styles.subHeader}>Catalogue Items</h2>

					{/* Search Bar for Catalogue Items */}


					{/* UI for selecting filters and sorting methods */}
					<select
						value={filter}
						onChange={(e) => {
							setFilter(e.target.value);
							setCurrentPage(1);
						}}>
						<option value="all">All Categories</option>
						{categories.map((category) => (
							<option key={category} value={category}>
								{category}
							</option>
						))}
					</select>

					<select value={sort} onChange={(e) => setSort(e.target.value)}>
						<option value="none">No Sorting</option>
						<option value="priceLowHigh">Price: Low to High</option>
						<option value="priceHighLow">Price: High to Low</option>
					</select>

					{/* Pagination Controls */}
					<div style={styles.pagination}>
						{Array.from({ length: totalPages }, (_, index) => (
							<button key={index + 1} style={currentPage === index + 1 ? styles.activePage : styles.pageButton} onClick={() => handlePageChange(index + 1)}>
								{index + 1}
							</button>
						))}
					</div>

					{/* Bulk Edit Section */}
					<div style={styles.bulkEdit}>
						<select
							value={selectedCategory}
							onChange={(e) => setSelectedCategory(e.target.value)}
							style={styles.input}
						>
							<option value="">Change Category</option>
							{categories.map((category) => (
								<option key={category} value={category}>{category}</option>
							))}
						</select>

						<select
							value={selectedSubCategory}
							onChange={(e) => setSelectedSubCategory(e.target.value)}
							style={styles.input}
						>
							<option value="">Select a Subcategory</option>
							{subCategories[selectedCategory]?.map((subCat) => (
								<option key={subCat} value={subCat}>
									{subCat}
								</option>
							))}
						</select>

						<input
							type="text"
							placeholder="New Price"
							value={bulkPrice}
							onChange={(e) => setBulkPrice(e.target.value)}
							style={styles.input}
						/>
						<textarea
							placeholder="New Description"
							value={bulkDescription}
							onChange={(e) => setBulkDescription(e.target.value)}
							style={styles.input}
						/>
						<button onClick={toggleSelectAllProducts} style={styles.buttonApprove}>Select All in Category</button>
						<button onClick={updateProducts} style={styles.buttonApprove}>Apply Changes</button>
					</div>

					{/* Displaying Items */}
					<input
						type="text"
						placeholder="Search items..."
						value={searchQuery}
						onChange={handleSearchChange}
						style={{ ...styles.input, margin: "10px 0" }} // Adjust styling as needed
					/>
					<table style={styles.table}>
						<thead style={styles.tableHeader}>
							<tr>
								<th>Select</th>
								<th>Image</th>
								<th>Item Name</th>
								<th>URL</th>
								<th>Description</th>
								<th>Price</th>
								<th>Category</th>
								<th>Subcategory</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.map((item) => (
								<tr key={item.id} style={styles.tableRow}>
									<td>
										<input
											type="checkbox"
											checked={selectedProducts.includes(item.id)}
											onChange={() => handleSelectProduct(item.id)}
										/>
									</td>
									<td>
										<img src={item.image} alt={item.name} style={{ width: "100px", height: "100px" }} />
									</td>
									<td>{item.name}</td>
									<td>{item.url}</td>
									<td>{item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description}</td>
									<td>{item.price.includes("$") ? item.price : `$${item.price}`}</td>
									<td>{item.category}</td>
									<td>
										<select
											value={item.subCategory || ""}
											onChange={(e) => handleSubCategoryChange(item.id, e.target.value)}
											style={styles.input}
										>
											<option value="">Select a Subcategory</option>
											{subCategories[item.category]?.map((subCat) => (
												<option key={subCat} value={subCat}>
													{subCat}
												</option>
											))}
										</select>
									</td>
									<td>
										<button
											onClick={() => deleteItem(item.id)}
											style={styles.buttonDeny}
										>
											Delete
										</button>
										<button
											onClick={() => window.open(item.url, '_blank')}
											style={styles.buttonApprove}
										>
											See Product
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}

			{!showAdmin && (
				<>
					<div style={styles.loading}>
						You are not authorized to visit this page. Please contact the site manager or login <a href="mytassel.com">here</a>.
					</div>
				</>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	div: {
		backgroundColor: "#2c6e49",
	},
	container: {
		padding: 20,
	},
	header: {
		textAlign: "center",
		color: "#333",
	},
	table: {
		width: "100%",
		borderCollapse: "collapse",
		marginTop: 20,
	},
	tableHeader: {
		backgroundColor: "#f4f4f4",
	},
	tableRow: {
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
		textAlign: "center",
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 4,
		padding: 8,
		width: "100%",
		marginBottom: 5,
	},
	buttonApprove: {
		backgroundColor: "#4CAF50",
		color: "white",
		padding: 10,
		marginRight: 10,
	},
	buttonDeny: {
		backgroundColor: "#f44336",
		color: "white",
		padding: 10,
	},
	loading: {
		textAlign: "center",
		padding: 20,
	},
	subHeader: {
		color: "#333",
		marginTop: 20,
	},
	buttonReevaluate: {
		backgroundColor: "#FFA500", // Orange color for reevaluate button
		color: "white",
		padding: 10,
		marginRight: 10,
	},
	popupContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		zIndex: 3,
		top: 0, // These changes ensure the popup
		bottom: 0, // covers the whole screen
		left: 0, // and is centered.
		right: 0,
		top: 150,
		borderRadius: 50,
	},
	popup: {
		borderRadius: 50,
	},
	// Add this style within your existing styles object
	activePage: {
		backgroundColor: '#4CAF50', // Example: green background for the active page
		color: 'white',
		padding: 10,
		borderRadius: 5,
		fontWeight: 'bold', // Make the text bold to highlight
	},
	maintenanceSection: {
		marginTop: 50,
		padding: 15,
		borderRadius: 5,
		borderWidth: 1,
		borderColor: "#ddd",
	},
	sectionHeader: {
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: 10,
	},
	buttonDisabled: {
		backgroundColor: "#ccc",
		color: "white",
		padding: 10,
		marginRight: 10,
		cursor: "not-allowed",
	},
	buttonContainer: {
		paddingBottom: 10,
	},
	buttonActive: {
		backgroundColor: "#4CAF50", // Green for active
		color: "white",
		padding: 10,
		marginRight: 10,
	},
	buttonInactive: {
		backgroundColor: "#f44336", // Red for inactive
		color: "white",
		padding: 10,
		marginRight: 10,
	}
});

// Utility function to generate institution code
const generateInstitutionCode = (type) => {
	const prefix = type === "University or College" ? "U" : type === "High School" ? "H" : "I";
	return `${prefix}${Math.floor(10000 + Math.random() * 90000)}`;
};

export default Admin;