import React from "react";
import { UserProvider } from "./Widgets/UserContext";
import Navigation from "./Widgets/Navigation";
import { BlurProvider } from "./Widgets/BlurContext";
import { TutorialProvider } from "./Screens/Helpers/Tutorials";

const App = () => {
  return (
    
      <BlurProvider>
        <UserProvider>
        <TutorialProvider>
          <Navigation />
        </TutorialProvider>
      </UserProvider>
    </BlurProvider>
  );
};

export default App;