import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

const Countdown = ({ targetDate, onCompleteMessage }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(targetDate);
            setTimeLeft(newTimeLeft);

            if (newTimeLeft.total <= 0) {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    if (timeLeft.total <= 0) {
        return (
            <View style={styles.container}>
                <Text style={styles.completeMessage}>{onCompleteMessage}</Text>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <Text style={styles.countdownText}>
                {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
            </Text>
        </View>
    );
};

const calculateTimeLeft = (targetDate) => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            total: difference,
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    } else {
        timeLeft = { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
        backgroundColor: '#fefde2', // Match the background color with other widgets
        borderRadius: 5,
        alignItems: 'center',
        shadowColor: "rgba(0, 0, 0, 0.5)",
        shadowOffset: { width: 0, height: 6 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    countdownText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#4c956c', // Match the text color with other widgets
        fontFamily: "Orpheus Pro", // Use the same font family
    },
    completeMessage: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#4c956c', // Match the text color with other widgets
        fontFamily: "Orpheus Pro", // Use the same font family
        textAlign: 'center',
    },
});

export default Countdown;
