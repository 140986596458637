import React, { useState } from "react";
import { View, Text, ScrollView, ImageBackground, Pressable, TouchableOpacity, Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/LandingStyles";
import Popup from "../Widgets/Popup";
import { useUser } from "../Widgets/UserContext";
import ComingSoon from "./ComingSoon";

const Landing = () => {
	const [isBlurred, setIsBlurred] = React.useState(false);
	const [popupType, setPopupType] = React.useState(null);

	// Set to true to show Coming Soon Screen
	const [isComingSoon, setIsComingSoon] = React.useState(false);
	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();
	console.warn(currentUserData);

	const navigation = useNavigation();

	React.useEffect(() => {
		if (currentUserData) {
			navigation.navigate("Hub");
		}
	}, [currentUserData, navigation]);

	const navigateToChecklist = (type) => {
		navigation.navigate("Checklist", { type });
	};

	const navigateToCatalogue = () => {
		navigation.navigate("Catalogue", { isFromLandingPage: true });
	};

	// Navigation
	const handleLinkPress = (pageName, popupType = null) => {
		console.log(`Navigating to ${pageName}`);
		if (popupType) {
			setIsBlurred(true);
			setPopupType(popupType);
		} else {
			if (isBlurred) {
				setIsBlurred(false); // Close any currently open popup
			} else {
				toggleBlur(pageName.toLowerCase()); // 'Register' will become 'register', 'Login' will become 'login'
			}
		}
	};

	// Blur
	const toggleBlur = (type = null) => {
		setIsBlurred(!isBlurred); // Toggle the blur
		setPopupType(type); // Set the popup type
	};

	const slideAnimDorm = React.useRef(new Animated.Value(-100)).current;
	const slideAnimApartment = React.useRef(new Animated.Value(-200)).current;
	const slideAnimGraduation = React.useRef(new Animated.Value(-300)).current;
	const slideAnimCatalogue = React.useRef(new Animated.Value(-400)).current;
	const slideAnimPrivacy = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTerms = React.useRef(new Animated.Value(-400)).current;

	const slideIn = () => {
		Animated.timing(slideAnimDorm, {
			toValue: 250,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimApartment, {
			toValue: 200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimGraduation, {
			toValue: 150,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: 100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: 50,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const slideOut = () => {
		Animated.timing(slideAnimDorm, {
			toValue: -100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimApartment, {
			toValue: -200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimGraduation, {
			toValue: -300,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	// Animation values for buttons
	const scaleAnim = React.useRef(new Animated.Value(1)).current;

	const handlePressIn = () => {
		Animated.spring(scaleAnim, {
			toValue: 0.95,
			useNativeDriver: true,
		}).start();
	};

	const handlePressOut = () => {
		Animated.spring(scaleAnim, {
			toValue: 1,
			useNativeDriver: true,
		}).start();
	};

	const [hoveredButton, setHoveredButton] = useState(null); // Initialize state

	return (
		<View style={isBlurred ? styles.blurredContainer : null}>
			{isComingSoon && <ComingSoon />}
			<ScrollView style={styles.body}>
				<View style={styles.section}>
					{isBlurred && (
						<View style={styles.popupContainer}>
							<Popup style={styles.popup} type={popupType} onClose={toggleBlur} />
						</View>
					)}

					<ImageBackground source={require("../assets/Landing Background.png")} style={styles.backgroundImage}>
						{isBlurred && <div style={{ ...styles.overlayBlur }} />}
						<View style={styles.headerBar}>
							<Pressable style={styles.logoArea} onPress={() => handleLinkPress("My Tassel - Landing")}>
								<Text style={styles.logoText}>MYTASSEL</Text>
							</Pressable>

							<View style={styles.loginArea}>
								<Pressable
									style={[
										styles.registerButton,
										hoveredButton === 'register' && styles.registerButtonHover
									]}
									onMouseEnter={() => setHoveredButton('register')}
									onMouseLeave={() => setHoveredButton(null)}
									onPress={() => handleLinkPress("Register")}
								>
									<Text style={styles.registerButtonText}>Registration</Text>
								</Pressable>
								<Pressable
									style={[
										styles.loginButton,
										hoveredButton === 'login' && styles.loginButtonHover
									]}
									onMouseEnter={() => setHoveredButton('login')}
									onMouseLeave={() => setHoveredButton(null)}
									onPress={() => handleLinkPress("Login")}
								>
									<Text style={styles.loginButtonText}>Login Now</Text>
								</Pressable>
							</View>
						</View>

						<View style={styles.contentArea}>
							<Text style={styles.headerText}>Graduation Planning.{"\n"}Simplified.</Text>
							<Text style={styles.descriptionText}>Your One-Stop Solution for FREE Digital Graduation Announcements, Event Sharing, and Registries</Text>

							<Pressable
								style={[
									styles.getStartedButton,
									hoveredButton === 'getStarted' && styles.getStartedButtonHover
								]}
								onMouseEnter={() => setHoveredButton('getStarted')}
								onMouseLeave={() => setHoveredButton(null)}
								onPress={() => handleLinkPress("Register")}
							>
								<Text style={styles.buttonText}>Get Started</Text>
							</Pressable>
						</View>

						<View
							style={styles.ribbonContainer}
							onMouseEnter={slideIn}
							onMouseLeave={slideOut}
							onPressIn={handlePressIn}
							onPressOut={handlePressOut}
						>
							<Text style={{ ...styles.ribbonText, width: 100, textAlign: 'center' }}>Resources</Text>
							<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimDorm }}>
								<Pressable
									onPress={() => navigateToChecklist("dorm")}
								>
									<Text style={styles.ribbonText}>Dorm Checklist</Text>
								</Pressable>
							</Animated.View>
							<Animated.View style={{ ...styles.ribbon2, bottom: slideAnimApartment }}>
								<Pressable
									onPress={() => navigateToChecklist("apartment")}
								>
									<Text style={styles.ribbonText}>Apartment Checklist</Text>
								</Pressable>
							</Animated.View>
							<Animated.View style={{ ...styles.ribbon3, bottom: slideAnimGraduation }}>
								<Pressable
									onPress={() => navigateToChecklist("graduation")}
								>
									<Text style={styles.ribbonText}>Graduation Checklist</Text>
								</Pressable>
							</Animated.View>

							<Animated.View style={{ ...styles.ribbon4, bottom: slideAnimCatalogue }}>
								<Pressable
									onPress={navigateToCatalogue}
								>
									<Text style={styles.ribbonText}>Catalogue</Text>
								</Pressable>
							</Animated.View>

							<Animated.View style={{ ...styles.ribbon5, bottom: slideAnimPrivacy }}>
								<Pressable
									onPress={() => navigation.navigate("Privacy")}
								>
									<Text style={styles.ribbonText}>Privacy Policy</Text>
								</Pressable>
							</Animated.View>

							<Animated.View style={{ ...styles.ribbon6, zIndex: 1002, bottom: slideAnimTerms }}>
								<Pressable
									onPress={() => navigation.navigate("Terms")}
								>
									<Text style={styles.ribbonText}>Terms & Conditions</Text>
								</Pressable>
							</Animated.View>

						</View>
					</ImageBackground>
				</View>
			</ScrollView>
		</View>
	);
};

export default Landing;
