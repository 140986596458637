export const buttonHoverStyle = (isHovered, buttonId, hoveredButton, isMobile, isTablet, isSmallDesktop) => ({
	backgroundColor: isHovered || hoveredButton === buttonId ? "#5fba86" : "#4c956c",
	borderRadius: 40,
	alignItems: "center",
	justifyContent: "center",
	width: isMobile ? 284 : isTablet ? 284 : isSmallDesktop ? 250 : 300,
	height: isMobile ? 55 : isTablet ? 55 : isSmallDesktop ? 50 : 60,
	marginBottom: isMobile ? 20 : 0,
	transform: [{ scale: isHovered || hoveredButton === buttonId ? 1.05 : 1 }],
});

export const imageHoverStyle = (isHovered) => ({
	transform: [{ scale: isHovered ? 1.025 : 1 }],
});
