import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../firebase"; // Assuming this exports your Firebase app instance
import { initializeUserDocument, checkAndAddTutorialSetting } from '../Screens/Helpers/Tutorials';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.email);
        const userDocSnap = await getDoc(userDocRef);
        
        // Check if this is a new user
        if (!userDocSnap.exists()) {
          // Initialize new user document with tutorial enabled
          await initializeUserDocument(user.email);
        } else {
          // Existing user - check and add tutorial setting if missing
          await checkAndAddTutorialSetting(user.email);
        }

        // Continue with existing logic
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setCurrentUser(user);
          setCurrentUserData(userData);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('currentUserData', JSON.stringify(userData));
        }
      } else {
        setCurrentUser(null);
        setCurrentUserData(null);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserData');
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  useEffect(() => {
    // alert("Checking for cached user data...");
    const cachedUser = localStorage.getItem('currentUser');
    const cachedUserData = localStorage.getItem('currentUserData');
    if (cachedUser && cachedUserData) {
      const userData = JSON.parse(cachedUserData);
      setCurrentUser(JSON.parse(cachedUser));
      setCurrentUserData(userData);
      // alert(`Welcome ${userData.firstName}`);
      // alert("Cached user data found and set.");
    } else {
      // alert("No cached user data found.");
    }
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, currentUserData, setCurrentUser, setCurrentUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);