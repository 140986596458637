import Bodoni72Smallcaps from "../assets/fonts/Bodoni 72 Smallcaps Book.ttf";
import { StyleSheet, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;
const isLargeDesktop = width > 1440;


const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
	},

	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		maxHeight: "100vh",
		zIndex: 1,
		justifyContent: "center",
		alignContent: "center",
	},

	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 28,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},

	backgroundImage: {
		flex: 1,
		resizeMode: "cover",
		minHeight: "100vh",
	},

	profileContainer: {
		alignItems: "center",
		marginVertical: 10,
		borderRadius: 250,
		zIndex: 40,
	},

	profileImage: {
		width: isMobile ? 150 : 250,
		height: isMobile ? 150 : 250,
		borderRadius: 250,
		marginBottom: 10,
		borderWidth: 3,
		borderColor: "#e4e4e4",
	},
	sealImage: {
		width: 75,
		height: 75,
		borderRadius: 60,
		marginTop: 175,
		marginRight: 175,
		position: "absolute",
	},
	welcomeText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 50 : 90,
		paddingBottom: 25,
		paddingTop: isMobile ? 25 : 50,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	instructionsContainer: {
		paddingTop: isMobile ? 0 : 60,
		marginBottom: isMobile ? 0 : -50,
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
		zIndex: 1,
	},
	instructions: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 20 : 35,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileName: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 40 : 75,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileDetails: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 20 : 25,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	formContainer: {
		marginHorizontal: 40,
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
		marginTop: isMobile ? 25 : 25,
		zIndex: 1,
	},
	forms: {
		flex: 1,
		marginHorizontal: isMobile ? 7.5 : 50,
		maxWidth: isMobile ? 300 : 650,
		position: 'relative', // Ensure relative positioning
		zIndex: 1,
	},
	inputContainer: {
		position: 'relative', // Ensure relative positioning
		zIndex: 1,
	},
	inputText: {
		position: "relative",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		color: "#fefde2",
		fontSize: isMobile ? 16 : 24,
		zIndex: 1,
	},
	inputField: {
		position: 'relative',
		flex: 0.5,
		borderWidth: 1,
		borderRadius: 27.5,
		padding: 10,
		marginVertical: 10,
		borderColor: "#e4e4e4",
		fontFamily: "Orpheus Pro",
		color: "#2c6e49",
		backgroundColor: "#fefde2",
		minHeight: 55,
		paddingHorizontal: 25,
		fontSize: isMobile ? 16 : 24,
		zIndex: 1, // Lower zIndex for input fields
	},
	buttonGroup: {},
	changeAccountButton: {
		padding: 12.5,
		backgroundColor: "#2c6e49",
		borderRadius: 27.5,
		width: 250,
		height: "auto",
		flex: 1,
		alignItems: "center",
		marginTop: 5,
	},
	saveButtonContainer: {
		alignItems: "center",
		marginVertical: 20,
	},
	saveButton: {
		padding: 12.5,
		backgroundColor: "#4c956c",
		borderRadius: 27.5,
		width: 284,
		height: 100,
		flex: 1,
		alignItems: "center",
		marginTop: isMobile ? 0 : 50,
	},
	saveButtonText: {
		fontSize: 24,
		color: "#e4e4e4",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	savePopup: {
		position: "absolute",
		bottom: 50,
		alignSelf: "center",
		backgroundColor: "#fefde2",
		padding: 10,
		borderRadius: 20,
		zIndex: 100,
	},
	savePopupText: {
		color: "#2c6e49", 
		fontSize: 16,
	},
	dropdown: {
		position: "relative",
		backgroundColor: "#fefde2",
		borderColor: "#e4e4e4",
		borderWidth: 1,
		borderRadius: 10,
		marginTop: 5,
		maxHeight: 200, // Set a max height for the dropdown
		overflow: "scroll", // Enable scrolling if there are many items
		zIndex: 12000, // Ensure it displays on top
	},
	dropdownItem: {
		paddingVertical: 10,
		paddingHorizontal: 15,
		borderBottomWidth: 1,
		borderBottomColor: "#e4e4e4",
		zIndex: 99,
	},
	dropdownText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 16 : 20,
		color: "#2c6e49",
	},
	nonEditableInput: {
		flex: 0.5,
		borderWidth: 1,
		borderRadius: 27.5,
		padding: 10,
		marginVertical: 10,
		borderColor: "#e4e4e4",
		backgroundColor: "#d3d3d3", // Light gray to indicate non-editability
		color: "#808080", // Darker gray text
		fontFamily: "Orpheus Pro",
		fontSize: 22,
		paddingHorizontal: 25,
	},
	addButton: {
		backgroundColor: "#fefde2",
		color: "#2c6e49",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 20,
		zIndex: 1001,
		borderRadius: 10,
		flex: 0.5,
		justifyContent: "center",
		paddingVertical: 10,
		paddingHorizontal: 15,
	},
	// Styles for the modal's outer container
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		zIndex: 3,
		backgroundColor: "#2c6e49",
		backgroundColor: "rgba(0, 0, 0, 0.75)",
		padding: 20,
		// maxWidth: "50%",
		// maxHeight: "50%",
		width: "100%",
		height: "50%",
		borderRadius: 50,
		zIndex: 98,
	},

	// Styles for the modal's inner content area
	modalContent: {
		backgroundColor: "#fefde2", // Keeping consistent with your color scheme
		paddingVertical: 10,
		paddingHorizontal: 25,
		borderRadius: 20,
		width: "80%",
		alignItems: "center", // Center content horizontally
		justifyContent: "center", // Align content to the top
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},

	// Styles for the text inside the modal
	modalTitle: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 30,
		color: "#fefde2",
		marginBottom: 15,
		textAlign: "center",
	},
	modalField: {
		position: "relative",
		minWidth: "50%",
		maxWidth: "50%",
		borderWidth: 1,
		borderRadius: 27.5,
		padding: 10,
		marginVertical: 10,
		borderColor: "#e4e4e4",
		fontFamily: "Orpheus Pro",
		color: "#2c6e49",
		backgroundColor: "#fefde2",
		paddingHorizontal: 25,
		fontSize: isMobile ? 16 : 24,
	},
	modalDropDown: {
		position: "relative",
		minWidth: "50%",
		maxWidth: "50%",
		marginVertical: 10,
		backgroundColor: "transparent",
	},

	modalText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 20,
		color: "#2c6e49",
		marginBottom: 15,
		textAlign: "center",
	},

	closeButton: {
		alignSelf: "flex-start", // Align close button to the right
		padding: 10,
		backgroundColor: "red",
		borderRadius: 100,
	},

	closeButtonText: {
		fontSize: 20,
		color: "#fefde2",
	},

	modalButton: {
		backgroundColor: "#2c6e49",
		padding: 10,
		borderRadius: 10,
		minWidth: "50%",
		alignItems: "center",
		marginTop: 20,
		zIndex: 1
	},
	modalButtonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#fefde2",
		fontSize: 20,
		textAlign: "center",
	},
	// Define styles for the dropdown container
	dropdownContainerStyle: {
		marginTop: 5,
		borderWidth: 1,
		borderRadius: 27.5,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 16 : 24,
		color: "#2c6e49",
		backgroundColor: "#fefde2",
		maxHeight: 250,
		zIndex: 10000, // Higher zIndex for dropdown container
		elevation: 10, // Use elevation for Android
	},
	addOrgSuccessPopup: {
		position: "absolute",
		bottom: 50,
		alignSelf: "center",
		backgroundColor: "#fefde2",
		padding: 10,
		borderRadius: 20,
		zIndex: 100,
		// Other styling as needed
	},
	addOrgSuccessPopupText: {
		color: "#2c6e49",
		fontSize: isMobile ? 12 : 16,
		// Other styling as needed
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		// backgroundColor: "rgba(0,0,0,0.7)", // translucent black
		backdropFilter: "blur(25px)", // Adjust the px value to control the amount of blur
		zIndex: 1,
	},

	viewPasswordText: {
		color: "#fefde2",
		fontSize: 16,
		paddingRight: 25,
		marginTop: -25,
	},
	resetPasswordButtonText: {
		color: "#2c6e49",
		fontSize: isMobile ? 12 : 16,
		paddingLeft: 0,
		marginTop: -30,
	},
});

export default styles;
